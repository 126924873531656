import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
// import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress, Collapse, fade, IconButton } from '@material-ui/core';
// import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { httpClient } from 'appUtility/Api';
import ReCAPTCHA from 'react-google-recaptcha';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ForgotPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  // const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [capcha, setCapcha] = useState('');
  const recaptchaRef = React.createRef();
  const [loading, setLoading] = React.useState(false);
  // const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();
  const [message, setMessage] = useState('');

  // useEffect(() => {
  //   if (send_forget_password_email) {
  //     setOpen(true);

  //     setTimeout(() => {
  //       dispatch(setForgetPassMailSent(false));
  //       history.push('/');
  //     }, 1500);
  //   }
  // }, [send_forget_password_email]);

  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    const data = {
      email: email,
      GRecaptchaResponse: capcha,
      baseURL: window.location.hostname === 'localhost' ? 'http://localhost:3000' : 'http://dev.synccare.com.au',
    };
    const response = await httpClient.post('frontend/forgot-password', data);
    if (response.data.success === true) {
      setLoading(false);
      setOpen(true);
      setMessage('A mail has been sent on your email address with reset password link.');
    } else {
      setLoading(false);
      setOpen(true);
      setMessage(response.data.message.GRecaptchaResponse[0]);
    }
  };

  const handleBack = () => {
    history.push('/');
  };

  // const onSubmit = () => {
  //   // dispatch(AuhMethods[method].onForgotPassword({ email }));
  //   setLoading(true);
  //   httpClient.post('frontend/forgot-password',qs.stringify
  //   ({
  //     email: email,
  //     GRecaptchaResponse: capcha,
  //     baseURL: window.location.hostname === 'localhost' ? "http://localhost:3000" : "http://dev.synccare.com.au"
  //   })).then(({data}) => {
  //     if(data.success === true){
  //       setLoading(false);
  //       setOpen(true);
  //       setMessage("A mail has been sent on your email address with reset password link.")
  //     }else{
  //       setLoading(false);
  //       setOpen(true);
  //       setMessage(data.message.GRecaptchaResponse[0]);
  //     }
  //   })
  // };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo-symbol.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Forgot Password
        </Typography>

        <form>
          <Box mb={5}>
            <TextField
              required
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={3}>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey="6Lfa0Z4dAAAAAEZUx3FOYPz8s7KaahSaX6Ou7qK8"
              onChange={e => setCapcha(recaptchaRef.current.getValue())}
            />
          </Box>
          <Box mb={5}>
            <Button onClick={handleBack} variant="contained" color="secondary" style={{ marginRight: '10px' }}>
              Back
            </Button>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
            {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
          </Box>

          {/* <Box>
            <Typography>
              Don't remember your email?
              <Box component="span" ml={2}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </Box>
            </Typography>
          </Box> */}
        </form>

        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {message}
          </Alert>
        </Collapse>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ForgotPassword;
