import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Button, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
// import AlertDelete from '../AlertDelete';
// import AlertDialog from '../AlertDialog';
import TableContactComponent from '../TableContactComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertContactView from '../AlertContactView';

const columns = [
  { id: 'firstName', label: 'FirstName' },
  { id: 'lastName', label: 'LastName' },
  { id: 'phone', label: 'Phone' },
  { id: 'email_username', label: 'Email' },
  { id: 'primaryContact', label: 'Primary Contact' },
  { id: 'hasAccessToAdminPanel', label: 'Admin Panel Access' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
const loginData = JSON.parse(sessionStorage.getItem('login'));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [companyID, setCompanyID] = useState(loginData && loginData.company.companyID);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);

  const [selectID, setSelectID] = useState('');
  // const [activeStatus, setActiveStatus] = useState('');
  // const [activateTable, setActivateTable] = useState('');
  // const [productData, setProductData] = useState([]);
  // const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    firstname: '',
    lastname: '',
    primaryContact: '',
    adminAccess: '',
  });
  const [filterData, setFilterData] = useState({
    firstname: '',
    lastname: '',
    primaryContact: '',
    adminAccess: '',
    removed: '',
  });

  useEffect(() => {
    if (
      filterData.firstname === '' &&
      filterData.lastname === '' &&
      filterData.primaryContact === '' &&
      filterData.adminAccess === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.firstname === ' ') filterData.firstname = '';
    if (filterData.lastname === ' ') filterData.lastname = '';
    if (filterData.primaryContact === ' ') filterData.primaryContact = '';
    if (filterData.adminAccess === ' ') filterData.adminAccess = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  // useEffect(() => {
  //   getContacts();
  // }, []);

  useEffect(() => {
    // setCompanyID(loginData && loginData.company.companyID);
    let stateStorage = JSON.parse(localStorage.getItem('contacts_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getContacts()
      : stateStorage.firstname == '' &&
        stateStorage.lastname == '' &&
        stateStorage.primaryContact == '' &&
        stateStorage.adminAccess == '' &&
        stateStorage.removed == false
      ? getContacts()
      : console.log('contacts');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      firstname: filterData.firstname,
      lastname: filterData.lastname,
      primaryContact: filterData.primaryContact,
      adminAccess: filterData.adminAccess,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('contacts_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.firstname || filterData.lastname || filterData.primaryContact || filterData.adminAccess) {
      httpClient
        .get(
          `company-contact/ByCompany/${companyID}/filter?firstName=${filterData.firstname}&lastName=${filterData.lastname}&primaryContact=${filterData.primaryContact}&hasAccessToAdminPanel=${filterData.adminAccess}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setContacts(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getContacts();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getContacts = () => {
    setLoading(true);
    httpClient.get(`company-contact/ByCompany/${companyID}?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setContacts(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(
        `company-contact/ByCompany/${companyID}?sort_by=${column.id}&direction=${
          direction ? 'asc' : 'desc'
        }&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setContacts(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `company-contact/ByCompany/${companyID}/filter?firstName=${filterData.firstname}&lastName=${filterData.lastname}&primaryContact=${filterData.primaryContact}&hasAccessToAdminPanel=${filterData.adminAccess}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setContacts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(`company-contact/ByCompany/${companyID}?direction=desc&pagination=${rowsPerPage}&page=${page}`)
          .then(({ data }) => {
            if (data.success === true) {
              setContacts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `company-contact/ByCompany/${companyID}/filter?firstName=${filterData.firstname}&lastName=${
              filterData.lastname
            }&primaryContact=${filterData.primaryContact}&hasAccessToAdminPanel=${filterData.adminAccess}&pagination=${+event
              .target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setContacts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient
          .get(`company-contact/ByCompany/${companyID}?direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setContacts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

  const handleView = id => {
    setSelectID(id);
    setOpenDialog(true);
  };

  const sendData = child => {
    child.open == false && setOpenDialog(false);
  };

  const handleEdit = id => {
    history.push(`/dataSource/addContact/${id}`);
  };

  //   const onChangeSearch = e => {
  //     setSearchText(e.target.value);
  //     e.target.value === '' && getstates();
  //   };
  //   const handleSearch = e => {
  //     if (e.key === 'Enter') {
  //       httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setContacts(data.data);
  //         } else {
  //           console.log('search failed');
  //         }
  //       });
  //     }
  //   };

  //   const handleDefault = e => {
  //     setSearchText(e.target.value);
  //   };

  return (
    <PageContainer heading="Contacts">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableContactComponent
              columns={columns}
              selectedProducts={contacts}
              title="List Contacts"
              handleView={handleView}
              handleEdit={handleEdit}
              //   handleActivate={handleActivate}
              //   handleDeactivate={handleDeactivate}
              //   handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}

              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}

              //   activateTable={activateTable}
              //   productData={productData}
              //   deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertContactView sendData={sendData} selectID={selectID} />}

      {/* {openDeleteDialog && <AlertDelete sendDelete={sendDelete} />} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListContacts;
