import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
import Components from './Components';
import Apps from './Apps';
import Extensions from './Extensions';
import Charts from './Charts';
import Maps from './Maps';
import Widgets from './Widgets';
import Metrics from './Metrics';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Error404 from './ExtraPages/404';
import Error from './ExtraPages/Error';

import TourGuide from './TourGuide';
import ResetPasswordPage from './Auth/ResetPassword';
import DataSource from './DataSource';
import Products from './Products';
import Order from './Order';
import Shipping from './Shipping';
import Management from './Management';

const loginToken = JSON.parse(sessionStorage.getItem('token'));

const RestrictedRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        loginToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/404',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  // const { authUser } = useSelector(({ auth }) => auth);
  console.log('window', window.location);
  return (
    <Route
      {...rest}
      render={props =>
        // <Component {...props} />
        // window.location.pathname.split('/')[1] == 'admin' ? (
        !loginToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/admin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  // const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if ((location.pathname === '' || location.pathname === '/') && loginToken) {
    return <Redirect to={'/dashboard'} />;
  } else if ((location.pathname === '' || location.pathname === '/') && !loginToken) {
    return <Redirect to={'/admin'} />;
  } else if (location.pathname === '/admin' && loginToken) {
    return <Redirect to={'/dashboard'} />;
  } else if (location.pathname === '/admin/' && loginToken) {
    return <Redirect to={'/dashboard'} />;
  } else if (location.pathname === '/admin/' && !loginToken) {
    return <Redirect to={'/admin'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <RestrictedRoute path="/dataSource" component={DataSource} />
        <RestrictedRoute path="/products" component={Products} />
        <RestrictedRoute path="/orders" component={Order} />
        <RestrictedRoute path="/shipping" component={Shipping} />
        <RestrictedRoute path="/management" component={Management} />

        {/* <Route path="/widgets" component={Widgets} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/components" component={Components} />
        <Route path="/extensions" component={Extensions} />
        <Route path="/visualization/chart" component={Charts} />
        <Route path="/visualization/map" component={Maps} />
        <Route path="/extra-pages" component={ExtraPages} />
        <Route path="/apps" component={Apps} /> */}
        <PublicRoute path="/admin" component={Login} />
        {/* <PublicRoute path="/signup" component={Signup} /> */}
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/ResetPassword" component={ResetPasswordPage} />
        <PublicRoute path="/404" component={Error} />

        <RestrictedRoute path="*" component={Error404} />
      </Switch>

      {/* {location.pathname !== '/signin' && location.pathname !== '/signup' && location.pathname !== '/forgot-password' && (
        <TourGuide />
      )} */}
    </React.Fragment>
  );
};

export default Routes;
