import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
// import AlertDelete from '../AlertDelete';
// import AlertDialog from '../AlertDialog';
import TableCustComponent from '../TableCustComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertViewDialog from '../AlertViewDetail';
import AlertCustomerDetail from '../AlertCustomerDetail';

const columns = [
  { id: 'customerID', label: 'Customer ID' },
  { id: 'erplyFullName', label: 'Customer Full Name' },
  { id: 'erplyCustomerType', label: 'Customer Type' },
  { id: 'erplyCustomerStreet', label: 'Address' },
  { id: 'erplyCustomerPhone', label: 'Phone' },
  { id: 'erplyCustomerEmail', label: 'Email' },
  { id: 'lastModifiedDateTime', label: 'Last Modified' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListCustomers = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);

  const [selectID, setSelectID] = useState('');
  // const [activeStatus, setActiveStatus] = useState('');
  // const [activateTable, setActivateTable] = useState('');
  // const [productData, setProductData] = useState([]);
  // const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    fullName: '',
    address: '',
    customerType: "",
    search: '',
  });
  const [filterData, setFilterData] = useState({
    fullName: '',
    address: '',
    customerType: "",
    search: '',
    removed: '',
  });

  useEffect(() => {
    if (
      filterData.fullName === '' && filterData.address === '' && filterData.customerType === '' && 
      filterData.search === '') {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.fullName === ' ') filterData.fullName = '';
    if (filterData.address === ' ') filterData.address = '';
    if (filterData.customerType === ' ') filterData.customerType = '';
    if (filterData.search === ' ') filterData.search = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  // useEffect(() => {
  //   getCustomers();
  // }, []);

  useEffect(() => {
    let stateStorage = JSON.parse(localStorage.getItem('customers_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getCustomers()
      : stateStorage.search == '' && stateStorage.address == '' && stateStorage.fullName == '' && stateStorage.customerType == '' && stateStorage.removed == false
      ? getCustomers()
      : console.log('customers');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      fullName: filterData.fullName,
      address: filterData.address,
      customerType: filterData.customerType,
      search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('customers_filter', JSON.stringify(filterData));
    setLoading(true);
    if (filterData.fullName || filterData.address || filterData.customerType || filterData.search) {
      httpClient.get(`frontend/customer/filter?q=${filterData.search}&erplyFullName=${filterData.fullName}&erplyCustomerStreet=${filterData.address}&erplyCustomerType=${filterData.customerType}`).then(({ data }) => {
        if (data.success === true) {
          setCustomers(data.data);
          setTotal(data.meta.total);
          // setRowsPerPage(data.meta.per_page);
          setPage(data.meta.current_page);
          setFrom(data.meta.from);
          setTo(data.meta.to);
          setLoading(false);
        }
      });
    } else {
      getCustomers();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getCustomers = () => {
    setLoading(true);
    httpClient.get(`frontend/customer?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setCustomers(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(
        `frontend/customer?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`,
      )
      .then(({ data }) => {
        if (data.success === true) {
          setCustomers(data.data);
        }
      });
  };

  console.log('submitted data', submittedData);

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `frontend/customer/filter?q=${filterData.search}&erplyFullName=${filterData.fullName}&erplyCustomerStreet=${filterData.address}&erplyCustomerType=${filterData.customerType}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `frontend/customer?direction=desc&pagination=${rowsPerPage}&page=${page}`
            )
          .then(({ data }) => {
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          });
  };


  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `frontend/customer/filter?q=${filterData.search}&erplyFullName=${filterData.fullName}&erplyCustomerStreet=${filterData.address}&erplyCustomerType=${filterData.customerType}&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient
          .get(
            `frontend/customer?direction=desc&pagination=${+event.target.value}&page=${1}`)
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setCustomers(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          });
  };

  const handleView = id => {
    setSelectID(id);
    setOpenDialog(true);
  };

  const sendData = (child) => {
      child.open == false && setOpenDialog(false);
  }

  //   const handleEdit = val => {
  //     history.push(`/customers/addcustomers/${val.memID}`);
  //   };

  //   const handleActivate = val => {
  //     setOpenDialog(true);
  //     setSelectID(val.memID);
  //     setActiveStatus('activate');
  //   };

  //   const handleDeactivate = val => {
  //     setOpenDialog(true);
  //     setSelectID(val.memID);
  //     setActiveStatus('Deactivate');
  //   };

  //   const sendDeactivate = child => {
  //     child.open == false && setOpenDialog(false);

  //     if (child.success === true) {
  //       httpClient.post(`/data/source-type/activate-deactivate/${selectID}`).then(({ data }) => {
  //         if (data.success) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           // setTimeout(() => {
  //           //   window.location.reload();
  //           // }, 1500);
  //           // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
  //           // getCustomers();
  //           setProductData(data.data);
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed to Update the Data Source');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //   };

  //   const handleDelete = val => {
  //     setSelectID(val.memID);
  //     setOpenDeleteDialog(true);
  //   };

  //   const sendDelete = child => {
  //     child.open === false && setOpenDeleteDialog(false);
  //     if (child.success === true) {
  //       httpClient.delete(`/data/source-type/${selectID}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           setDeleteData(data.data);
  //           getCustomers();
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed to Delete the Data Source');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //   };

  //   const onChangeSearch = e => {
  //     setSearchText(e.target.value);
  //     e.target.value === '' && getstates();
  //   };
  //   const handleSearch = e => {
  //     if (e.key === 'Enter') {
  //       httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setCustomers(data.data);
  //         } else {
  //           console.log('search failed');
  //         }
  //       });
  //     }
  //   };

  //   const handleDefault = e => {
  //     setSearchText(e.target.value);
  //   };

  return (
    <PageContainer heading="Customers">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableCustComponent
              columns={columns}
              selectedProducts={customers}
              title="List Customers"
              handleView={handleView}
              //   handleEdit={handleEdit}
              //   handleActivate={handleActivate}
              //   handleDeactivate={handleDeactivate}
              //   handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              //   activateTable={activateTable}
              //   productData={productData}
              //   deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertCustomerDetail sendData={sendData} selectID={selectID} />}

      {/* {openDeleteDialog && <AlertDelete sendDelete={sendDelete} />} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListCustomers;
