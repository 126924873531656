import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
// import AlertDelete from '../AlertDelete';
// import AlertDialog from '../AlertDialog';
import TableStockComponent from '../TableStockComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertSOHDetail from '../AlertSOHDetail';
import AlertSOHVariation from '../AlertSOHVariation';

const columns = [
  { id: 'sohID', label: ' ' },
  { id: 'erplyproductName', label: 'Stock Code ' },
  { id: 'erplyproductName', label: 'Stock Name' },
  { id: 'erplyWarehouseName', label: 'Total SOH' },
  { id: 'erplyWarehouseName', label: 'Berwick' },
  { id: 'erplyWarehouseName', label: 'South Yarra' },
  { id: 'erplyWarehouseName', label: 'Mascot' },
  { id: 'erplyWarehouseName', label: 'Narellan' },
  { id: 'erplyWarehouseName', label: 'Beenleigh' },
  { id: 'erplyWarehouseName', label: 'Tuggerah' },
  // { id: 'productPrice', label: 'Price' },
  { id: 'lastModifiedDateTime', label: 'Last Modified' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const StockOnHand = () => {
  const [stockInHand, setStockInHand] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogVariation, setOpenDialogVariation] = useState(false);

  const [selectID, setSelectID] = useState('');
  // const [activeStatus, setActiveStatus] = useState('');
  // const [activateTable, setActivateTable] = useState('');
  // const [productData, setProductData] = useState([]);
  // const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    // productCode: '',
    // productGroup: '',
    // brand: '',
    // category: '',
    // status: '',
    // name: '',
    productName: '',
    productType: '',
  });
  const [filterData, setFilterData] = useState({
    // productCode: '',
    // productGroup: '',
    // brand: '',
    // category: '',
    // status: '',
    // name: '',
    // webActive: '',
    // productType: '',
    productName: '',
    productType: '',
    removed: '',
  });

  useEffect(() => {
    if (
      // filterData.productCode === '' &&
      // filterData.productGroup === '' &&
      // filterData.brand === '' &&
      // filterData.category === '' &&
      // filterData.status === '' &&
      // filterData.name === '' &&
      filterData.productName === '' &&
      filterData.productType === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    // if (filterData.productCode === ' ') filterData.productCode = '';
    // if (filterData.productGroup === ' ') filterData.productGroup = '';
    // if (filterData.brand === ' ') filterData.brand = '';
    // if (filterData.category === ' ') filterData.category = '';
    // if (filterData.status === ' ') filterData.status = '';
    // if (filterData.name === ' ') filterData.name = '';
    if (filterData.productName === ' ') filterData.productName = '';
    if (filterData.productType === ' ') filterData.productType = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  // useEffect(() => {
  //   getStockInHand();
  // }, []);

  useEffect(() => {
    let stateStorage = JSON.parse(localStorage.getItem('stockInHand_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getStockInHand()
      : // stateStorage.productCode == '' &&
      // stateStorage.productGroup == '' &&
      // stateStorage.brand == '' &&
      // stateStorage.category == '' &&
      // stateStorage.status == '' &&
      // stateStorage.name &&
      stateStorage.productName == '' && stateStorage.productType == '' && stateStorage.removed == false
      ? getStockInHand()
      : console.log('stockInHand');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      // productCode: filterData.productCode,
      // productGroup: filterData.productGroup,
      // brand: filterData.brand,
      // category: filterData.category,
      // status: filterData.status,
      // name: filterData.name,
      productName: filterData.productName,
      productType: filterData.productType,
      // search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('stockInHand_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      // filterData.productCode ||
      // filterData.productGroup ||
      // filterData.brand ||
      // filterData.category ||
      // filterData.status ||
      // filterData.name ||
      filterData.productName ||
      filterData.productType
    ) {
      httpClient
        .get(`stock-in-hand/filter?productName=${filterData.productName}&productType=${filterData.productType}`)
        .then(({ data }) => {
          if (data.success === true) {
            setStockInHand(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getStockInHand();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getStockInHand = () => {
    setLoading(true);
    httpClient.get(`stock-in-hand?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setStockInHand(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`stock-in-hand?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setStockInHand(data.data);
        }
      });
  };

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `stock-in-hand/filter?productName=${filterData.productName}&productType=${filterData.productType}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setStockInHand(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`stock-in-hand?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setStockInHand(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `stock-in-hand/filter?productName=${filterData.productName}&productType=${
              filterData.productType
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setStockInHand(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`stock-in-hand?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setStockInHand(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleView = id => {
    setSelectID(id);
    setOpenDialog(true);
  };

  // const handleViewVariation = id => {
  //   setSelectID(id);
  //   setOpenDialogVariation(true);
  // };

  const sendData = child => {
    child.open == false && setOpenDialog(false);
  };

  // const sendVariationData = child => {
  //   child.open == false && setOpenDialogVariation(false);
  // };

  return (
    <PageContainer heading="Stocks">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableStockComponent
              columns={columns}
              selectedProducts={stockInHand}
              title="Stock on Hand"
              handleView={handleView}
              // handleViewVariation={handleViewVariation}
              handleViewVariation={handleView}
              //   handleEdit={handleEdit}
              //   handleActivate={handleActivate}
              //   handleDeactivate={handleDeactivate}
              //   handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              //   activateTable={activateTable}
              //   productData={productData}
              //   deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertSOHDetail sendData={sendData} selectID={selectID} />}

      {/* {openDialogVariation && <AlertSOHDetail sendVariationData={sendVariationData} selectID={selectID} />} */}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default StockOnHand;
