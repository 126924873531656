export const metrics = {
  eCommerceData: [
    {
      title: 2380,
      subTitle: 'Orders this year',
      color: '#6200EE',
      imageIcon: '/images/dashboard/icon-cart.png',
    },
    {
      title: 29380,
      color: '#00C4B4',
      subTitle: 'Revenue this year',
      imageIcon: '/images/dashboard/icon-wallet.png',
    },
    {
      title: 2870,
      color: '#0795F4',
      subTitle: 'Visits this year',
      imageIcon: '/images/dashboard/icon-touch.png',
    },
    {
      title: 8380,
      color: '#8DCD03',
      subTitle: 'Queries this year',
      imageIcon: '/images/dashboard/icon-headset-mic.png',
    },
  ],
  dataMetrics: [
    {
      title: 2380,
      bgColor: '#6200EE',
      color: '#ffffff',
      desc: 'Orders this year',
      imageIcon: '/images/dashboard/icon-cart.png',
    },
    {
      title: 29380,
      color: '#ffffff',
      bgColor: '#00C4B4',
      desc: 'Revenue this year',
      imageIcon: '/images/dashboard/icon-wallet.png',
    },
    {
      title: 2870,
      bgColor: '',
      color: '#0795F4',
      desc: 'Visits this year',
      imageIcon: '/images/dashboard/icon-touch.png',
    },
  ],
  activeUsers: [
    { month: 'Jan', users: 10 },
    { month: 'Feb', users: 50 },
    { month: 'Mar', users: 100 },
    { month: 'Apr', users: 75 },
    { month: 'May', users: 125 },
  ],
  orders: [
    { name: 'Page A', pv: 200 },
    { name: 'Page B', pv: 300 },
    { name: 'Page C', pv: 500 },
    { name: 'Page D', pv: 350 },
    { name: 'Page D', pv: 510 },
  ],
  extraRevenue: [
    { month: 'Jan', amt: 220 },
    { month: 'Feb', amt: 142 },
    { month: 'Mar', amt: 125 },
    { month: 'Apr', amt: 145 },
    { month: 'May', amt: 205 },
    { month: 'Jun', amt: 230 },
    { month: 'Jul', amt: 200 },
    { month: 'Aug', amt: 115 },
    { month: 'Sep', amt: 80 },
    { month: 'Oct', amt: 85 },
    { month: 'Nov', amt: 120 },
    { month: 'Dec', amt: 180 },
  ],
  trafficRaise: [
    { month: 'Jan', traffic: 20 },
    { month: 'Feb', traffic: 70 },
    { month: 'Mar', traffic: 120 },
    { month: 'Apr', traffic: 50 },
    { month: 'May', traffic: 130 },
  ],
  revenueGrowth: [
    { month: 'Jan', revenue: 100 },
    { month: 'Feb', revenue: 75 },
    { month: 'Mar', revenue: 120 },
    { month: 'Apr', revenue: 100 },
    { month: 'May', revenue: 130 },
    { month: 'Jun', revenue: 145 },
    { month: 'Jul', revenue: 190 },
    { month: 'Aug', revenue: 150 },
    { month: 'Sep', revenue: 170 },
    { month: 'Oct', revenue: 140 },
    { month: 'Nov', revenue: 100 },
    { month: 'Dec', revenue: 120 },
  ],
  trafficData: [
    { month: 'Jan', traffic: 100 },
    { month: 'Feb', traffic: 120 },
    { month: 'Mar', traffic: 150 },
    { month: 'Apr', traffic: 140 },
    { month: 'May', traffic: 160 },
    { month: 'Jun', traffic: 120 },
    { month: 'Jul', traffic: 170 },
    { month: 'Aug', traffic: 180 },
    { month: 'Sep', traffic: 190 },
  ],
  income: [
    { month: 'Jan', income: 10 },
    { month: 'Feb', income: 50 },
    { month: 'Mar', income: 100 },
    { month: 'Apr', income: 75 },
    { month: 'May', income: 125 },
    { month: 'Jun', income: 175 },
  ],
  queries: [
    { month: 'Jan', amt: 2210 },
    { month: 'Feb', amt: 1600 },
    { month: 'Mar', amt: 2210 },
    { month: 'Apr', amt: 800 },
    { month: 'May', amt: 800 },
    { month: 'Jun', amt: 2220 },
    { month: 'Jul', amt: 2210 },
    { month: 'Aug', amt: 1810 },
    { month: 'Sep', amt: 500 },
    { month: 'Oct', amt: 1000 },
    { month: 'Nov', amt: 1500 },
    { month: 'Dec', amt: 2000 },
  ],
  traffics: [
    { month: 'Jan', traffic: 100 },
    { month: 'Feb', traffic: 75 },
    { month: 'Mar', traffic: 150 },
    { month: 'Apr', traffic: 50 },
    { month: 'May', traffic: 130 },
    { month: 'Jun', traffic: 115 },
    { month: 'Jul', traffic: 160 },
    { month: 'Aug', traffic: 110 },
    { month: 'Sep', traffic: 145 },
    { month: 'Oct', traffic: 125 },
    { month: 'Nov', traffic: 170 },
    { month: 'Dec', traffic: 160 },
  ],
};
