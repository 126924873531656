import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { httpClient } from 'appUtility/Api';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { Tab } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  headerInside: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  close: {
    color: '#fff',
  },
  content: {
    padding: '40px',
  },
  gridColumn: {
    padding: '20px',
  },
  mb: {
    marginBottom: '20px',
  },
  date: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontStyle: 'italic',
    fontSize: '12px',
  },
  sub: {
    color: '#666',
  },
  loader: {
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  card: {
    fontSize: '15px',
  },
  cardHeading: {
    padding: '20px',
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
  },
  productHead: {
    marginBottom: '10px',
    fontWeight: '900',
  },
  true: {
    padding: '5px 10px',
    borderRadius: '10px',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontSize: '10px',
    width: 'fit-content',
  },
  false: {
    padding: '5px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '10px',
    width: 'fit-content',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
  },
  boxShadow: {
    padding: '10px !important',
    margin: '15px 5px',
    borderRadius: '3px',
    border: '1px solid #d8d8d8',
    // boxShadow:"0 3px 10px rgb(0 0 0 / 0.2)"
  },
}));

const AlertSOHVariation = props => {
  const classes = useStyles();

  const [alertData, setAlertData] = useState({
    open: true,
  });

  const [value, setValue] = useState('1');

  const [productData, setProductData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    props.selectID &&
      httpClient.get(`frontend/product/variation/${props.selectID}`).then(({ data }) => {
        if (data.success == true) {
          setProductData(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //   console.log('props', props);

  props.sendVariationData(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose} maxWidth={'lg'} fullWidth={true}>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <DialogTitle className={classes.header}>
            <div className={classes.headerInside}>
              <div>
                <h3>{productData.erplyVariationProductName ? productData.erplyVariationProductName : '-'}</h3>
              </div>
              <IconButton onClick={handleClose} className={classes.close}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Card className={classes.card}>
              <Grid item xs={12} sm={12} className={classes.cardHeading}>
                Product Code : {productData.productCode ? productData.productCode : '-'}
              </Grid>
              <Grid container spacing={4} className={classes.gridColumn}>
                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Name</div>
                  <div className={classes.sub}>
                    {' '}
                    {productData.erplyVariationProductName ? productData.erplyVariationProductName : '-'}{' '}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div className={classes.productHead}>SALES PRICE</div> <div />
                  <div>Net Price </div>{' '}
                  <div className={classes.sub}>
                    {' '}
                    {productData.erplyVariationPrice ? '$' + productData.erplyVariationPrice : '-'}{' '}
                  </div>
                </Grid>

                {/* <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Product Group </div>
                  <div className={classes.sub}>
                    {productData.productGroupID}
                    {productData.erplyWarehouseCompanyCode && ' ' + '(' + productData.erplyWarehouseCompanyName2 + ')'}
                  </div>
                </Grid> */}

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Status </div>
                  {productData.erplyVariationActive == '1' ? (
                    <div className={classes.true}> ACTIVE </div>
                  ) : (
                    <div className={classes.false}> IN-ACTIVE </div>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Sales Price + Tax </div>{' '}
                  <div className={classes.sub}>
                    {' '}
                    {productData.erplyVariationPriceWithTax ? '$' + productData.erplyVariationPriceWithTax : '-'}{' '}
                  </div>
                </Grid>

                {/* <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Product Category </div> <div className={classes.sub}> {productData.productCategoryID} </div>
                </Grid> */}

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Product Type </div>
                  {productData.erplyVariationProductType == 'MATRIX' ? (
                    <div className={classes.true}>{productData.erplyVariationProductType}</div>
                  ) : (
                    <div className={classes.false}>{productData.erplyVariationProductType}</div>
                  )}
                </Grid>
                {/* 
                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Supplier Name </div> <div className={classes.sub}> {productData.productSupplierID} </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Brand</div> <div className={classes.sub}> {productData.productBrandID} </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>GST Rate </div> <div className={classes.sub} />
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Web Active </div>{' '}
                  {productData.productWebActive != '1' ? (
                    <div className={classes.true}>YES</div>
                  ) : (
                    <div className={classes.false}>No</div>
                  )}
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Description </div> <div className={classes.sub}> {productData.productShortDescription} </div>
                </Grid>

                <Grid item xs={12} sm={12} className={classes.gridRow}>
                  <div>Long Description </div> <div className={classes.sub}> {productData.productlongDescription} </div>
                </Grid> */}
                <Grid item xs={12} sm={12} className={classes.boxShadow}>
                  <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}>
                          <Tab label="Description" value="1" />
                          <Tab label="Long Description" value="2" />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        {productData.erplyVariationDescription ? productData.erplyVariationDescription : '-'}
                      </TabPanel>
                      <TabPanel value="2">
                        {' '}
                        {productData.erplyVariationLongDescription ? productData.erplyVariationLongDescription : '-'}{' '}
                      </TabPanel>
                    </TabContext>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className={classes.date}>
                    <div>ID: {productData.variationID}</div>
                    <div>
                      Last Updated:{' '}
                      {productData.lastModifiedDateTime == '0000-00-00 00:00:00'
                        ? '-'
                        : moment(productData.lastModifiedDateTime).format('dddd, Do MMMM YYYY HH:mm A')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </DialogContent>
        </div>
      )}
    </Dialog>
  );
};

export default AlertSOHVariation;
