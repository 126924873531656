import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
// import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, fade } from '@material-ui/core';
// import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { Link } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';
import ReCAPTCHA from 'react-google-recaptcha';
// import { history } from 'redux/store';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import { httpClient } from 'appUtility/Api';
const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

//variant = 'default', 'standard'
const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [capcha, setCapcha] = useState('');
  const recaptchaRef = React.createRef();

  // const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  var [disable, setDisable] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  //async/await ==> function
  const onSubmit = async e => {
    e.preventDefault();
    setLoading(true);
    setDisable(true);
    var data = {
      Username: email,
      password: password,
      GRecaptchaResponse: capcha,
    };
    const response = await httpClient.post('frontend/login', data);
    if (response.data.success === true) {
      sessionStorage.setItem('token', JSON.stringify(response.data.data.access_token));
      sessionStorage.setItem('login', JSON.stringify(response.data.data));
      setTimeout(function() {
        setOpen(true);
        setMessage('Successfully Logged in');
        setMessageState('success');
        setLoading(false);
      }, 1000);
      setTimeout(function() {
        // history.push('/dashboard');
        window.location = '/dashboard';
      }, 2000);
    } else {
      setTimeout(function() {
        setOpen(true);
        !response.data.error ? setMessage(response.data.message[0]) : setMessage(response.data.error);
        setMessageState('error');
        setLoading(false);
      }, 1000);
    }
  };

  // const onSubmit = () => {
  //   // dispatch(AuhMethods[method].onLogin({ email, password }));
  //   setLoading(true);
  //   httpClient
  //     .post(
  //       'frontend/login',
  //       qs.stringify({
  //         Username: email,
  //         password: password,
  //         GRecaptchaResponse: capcha,
  //       }),
  //     )
  //     .then(({ data }) => {
  //       if (data.success === true) {
  //         sessionStorage.setItem('token', JSON.stringify(data.data.access_token));
  //         sessionStorage.setItem('login', JSON.stringify(data.data));
  //         setTimeout(function() {
  //           setOpen(true);
  //           setMessage('Successfully Logged in');
  //           setMessageState('success');
  //           setLoading(false);
  //         }, 1000);
  //         setTimeout(function() {
  //           // history.push('/dashboard');
  //           window.location = '/dashboard';
  //         }, 2000);
  //       } else {
  //         setTimeout(function() {
  //           setOpen(true);
  //           !data.error ? setMessage(data.message[0]) : setMessage(data.error);
  //           setMessageState('error');
  //           setLoading(false);
  //         }, 1000);
  //       }
  //     });
  // };

  if (email === '' && password === '' && capcha === '') {
    disable = true;
  } else {
    disable = false;
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo-symbol.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Login
        </Typography>
        <form>
          <Box>
            <TextField
              required
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={3}>
            <TextField
              required
              type="password"
              label={<IntlMessages id="appModule.password" />}
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={3}>
            <ReCAPTCHA
              ref={recaptchaRef}
              // sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
              sitekey="6Lfa0Z4dAAAAAEZUx3FOYPz8s7KaahSaX6Ou7qK8"
              onChange={e => setCapcha(recaptchaRef.current.getValue())}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            {/* <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox name="checkedA" />}
              label="Remember me"
            /> */}
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <Link to="/forgot-password">
                <IntlMessages id="appModule.forgotPassword" />
              </Link>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={disable}>
              <IntlMessages id="appModule.signIn" />
            </Button>
            {loading ? <CircularProgress size={20} style={{ marginLeft: '10px' }} /> : ''}

            {/* <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
              <NavLink to="/signup">
                <IntlMessages id="signIn.signUp" />
              </NavLink>
            </Box> */}
          </Box>
        </form>

        {/* {dispatch(AuhMethods[method].getSocialMediaIcons())} */}

        <ContentLoader />

        <Snackbar
          autoHideDuration={3000}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={open}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={messageState}>
            {message}
          </Alert>
        </Snackbar>
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
