import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Button, Card, Checkbox, FormControlLabel, Grid, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function MyProfile() {
  const classes = useStyles();
  const loginData = JSON.parse(sessionStorage.getItem('login'));
  const [profileDetails, setProfileDetails] = useState({
    firstName: '',
    lastName: '',
    username: '',
    position: '',
    mobile: '',
    phone: '',
    contactActive: '',
  });

  useEffect(() => {
    setProfileDetails({
      ...profileDetails,
      firstName: loginData && loginData.company.firstName,
      lastName: loginData && loginData.company.lastName,
      username: loginData && loginData.company.email_username,
      position: loginData && loginData.company.position,
      phone: loginData && loginData.company.phone,
      mobile: loginData && loginData.company.mobile,
      contactActive: loginData && loginData.company.contactActive == 1 ? true : false,
    });
  }, []);

  const handleBack = () => {
    history.push('/dashboard');
  };
  return (
    <PageContainer heading={'My Profile'}>
      <Card className={classes.root}>
        <form className={classes.form}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="First Name"
                variant="outlined"
                name="firstName"
                type="text"
                value={profileDetails.firstName}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Last Name"
                variant="outlined"
                name="lastName"
                type="text"
                value={profileDetails.lastName}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Username"
                variant="outlined"
                name="username"
                type="text"
                value={profileDetails.username}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Position"
                variant="outlined"
                name="position"
                type="text"
                value={profileDetails.position}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Phone"
                variant="outlined"
                name="phone"
                type="text"
                value={profileDetails.phone}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Mobile"
                variant="outlined"
                name="mobile"
                type="text"
                value={profileDetails.mobile}
                className={classes.text}
                // onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="contactActive"
                    checked={profileDetails.contactActive}
                    // onChange={handleCheck}
                    value={profileDetails.contactActive}
                    color="primary"
                  />
                }
                label="Profile Active?"
              />
            </Grid>
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Back
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </PageContainer>
  );
}

export default MyProfile;
