import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress, Collapse, fade, IconButton } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import { useHistory, useLocation } from 'react-router-dom';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import { httpClient } from 'appUtility/Api';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: fade(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: fade(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = (props, { method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  // const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const [open, setOpen] = React.useState(false);
  var [email, setEmail] = useState('');
  var [encemail, setEncemail] = useState('');
  var [rcode, setRcode] = useState('');
  var [encrcode, setEncrcode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  var [disable, setDisable] = useState(true);
  var [msg, setMsg] = useState('');
  var [alertVal, setAlertVAl] = useState('');

  const [loading, setLoading] = React.useState(false);
  const classes = useStyles({ variant });
  const history = useHistory();
  const location = useLocation();

  var email = location.search.split('?email=')[1].split('&')[0];
  var encemail = location.search.split('&encemail=')[1].split('&')[0];
  var rcode = location.search.split('&rcode=')[1].split('&')[0];
  var encrcode = location.search.split('&encrcode=')[1];

  const onSubmit = () => {
    // dispatch(AuhMethods[method].onForgotPassword({ email }));
    setLoading(true);
    httpClient
      .post(
        'frontend/reset-password',
        qs.stringify({
          email: email,
          encemail: encemail,
          rcode: rcode,
          encrcode: encrcode,
          password: password,
          comfirmPassword: confirmPassword,
        }),
      )
      .then(({ data }) => {
        if (data.success) {
          setOpen(true);
          setMsg('Password Reset Successful');
          setAlertVAl('success');
          setLoading(false);
          setPassword('');
          setConfirmPassword('');
          setTimeout(() => {
            history.push('/admin');
          }, 1500);
        } else {
          setOpen(true);
          setMsg('Password Reset Failed');
          setAlertVAl('error');
          setLoading(false);
        }
      });
  };

  if (password === confirmPassword) {
    disable = false;
  }

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/logo-symbol.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Password
        </Typography>
        <form>
          <Box>
            <TextField
              required
              label="Email"
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              disabled={true}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box>
            <TextField
              required
              label="Password"
              fullWidth
              onChange={event => setPassword(event.target.value)}
              // defaultValue={password}
              margin="normal"
              type="password"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={5}>
            <TextField
              required
              label="Confirm Password"
              fullWidth
              onChange={event => setConfirmPassword(event.target.value)}
              // defaultValue={confirmPassword}
              margin="normal"
              type="password"
              variant="outlined"
              className={classes.textFieldRoot}
              helperText={disable === true && 'Password does not match!'}
            />
          </Box>

          <Box mb={5}>
            <Button onClick={onSubmit} variant="contained" color="primary" disabled={disable}>
              Reset
            </Button>
            {loading && <CircularProgress size={25} style={{ marginLeft: '10px' }} />}
          </Box>

          {/* <Box>
            <Typography>
              Don't remember your email?
              <Box component="span" ml={2}>
                <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link>
              </Box>
            </Typography>
          </Box> */}
        </form>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity={alertVal}
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            {msg}
          </Alert>
        </Collapse>
        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default ResetPassword;
