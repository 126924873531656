import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  const [group, setGroup] = useState([]);
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    getGroups();
    getCategories();
    getBrands();
  }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  const getGroups = () => {
    httpClient.get('/group/all').then(({ data }) => {
      if (data) {
        setGroup(data.data);
      } else {
      }
    });
  };

  const getCategories = () => {
    httpClient.get('/product-category/all').then(({ data }) => {
      if (data) {
        setCategories(data.data);
      } else {
      }
    });
  };

  const getBrands = () => {
    httpClient.get('/brand/all').then(({ data }) => {
      if (data) {
        setBrands(data.data);
      } else {
      }
    });
  };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  var groupName, categoryName, brandName;
  group &&
    group.map(grp => {
      if (grp.groupID == props.submittedData.productGroup) {
        groupName = grp.erplyGroupName;
      }
    });

  categories &&
    categories.map(grp => {
      if (grp.categoryID == props.submittedData.category) {
        categoryName = grp.erplyCategoryName;
      }
    });

  brands &&
    brands.map(brand => {
      if (brand.brandID == props.submittedData.brand) {
        brandName = brand.erplyBrandName;
      }
    });

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo}  style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Product Code </InputLabel>
                  <TextField
                    variant="outlined"
                    name="productCode"
                    value={props.filterData.productCode}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                {/* <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="active"
                      value={props.filterData.active}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">&nbsp;</MenuItem>
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Product Group</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="productGroup"
                      value={props.filterData.productGroup}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Product Group</MenuItem>
                      {group.map(item => (
                        <MenuItem value={item.groupID}>{item.erplyGroupName}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Brand</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="brand"
                      value={props.filterData.brand}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Brand</MenuItem>
                      {brands && brands.map(item => <MenuItem value={item.brandID}>{item.erplyBrandName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Category</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="category"
                      value={props.filterData.category}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Category</MenuItem>
                      {categories &&
                        categories.map(item => <MenuItem value={item.categoryID}>{item.erplyCategoryName}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Status</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="status"
                      value={props.filterData.status}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Status</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="0">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Product Name</InputLabel>
                  <TextField
                    variant="outlined"
                    name="name"
                    value={props.filterData.name}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Web Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="webActive"
                      value={props.filterData.webActive}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select </MenuItem>
                      <MenuItem value="1">Web Active</MenuItem>
                      <MenuItem value="0">Web Inactive</MenuItem>
                      <MenuItem value="2">All Products</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Display Product Type</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="productType"
                      value={props.filterData.productType}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Product Type</MenuItem>
                      <MenuItem value="MATRIX">MATRIX</MenuItem>
                      <MenuItem value="PRODUCT">PRODUCT</MenuItem>
                      {/* <MenuItem value="2">All Products</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}

        {props.submittedData.productCode ||
        props.submittedData.productGroup ||
        props.submittedData.brand ||
        props.submittedData.category ||
        props.submittedData.status ||
        props.submittedData.name ||
        props.submittedData.webActive ||
        props.submittedData.productType ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.productCode && (
              <p>
                Product Code: <span>{props.submittedData.productCode} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('productCode')} />
              </p>
            )}
            {props.submittedData.productGroup && (
              <p>
                Group: <span> {groupName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('productGroup')} />
              </p>
            )}
            {props.submittedData.brand && (
              <p>
                Brand:<span> {brandName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('brand')} />
              </p>
            )}

            {props.submittedData.category && (
              <p>
                Category: <span>{categoryName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('category')} />
              </p>
            )}
            {props.submittedData.status && (
              <p>
                Status: <span>{props.submittedData.status == '1' ? 'Active' : 'Inactive'} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('status')} />
              </p>
            )}
            {props.submittedData.name && (
              <p>
                Name:<span> {props.submittedData.name} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('name')} />
              </p>
            )}
            {props.submittedData.webActive && (
              <p>
                Web Active:{' '}
                <span>
                  {props.submittedData.webActive == '1' && 'Yes'}
                  {props.submittedData.webActive == '0' && 'No'}
                  {props.submittedData.webActive == '2' && 'All'}
                </span>
                <Close fontSize="small" onClick={() => props.handleRemove('webActive')} />
              </p>
            )}
            {props.submittedData.productType && (
              <p>
                Product Type: <span>{props.submittedData.productType}</span>
                <Close fontSize="small" onClick={() => props.handleRemove('productType')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          // handleEdit={props.handleEdit}
          // handleActivate={props.handleActivate}
          // handleDeactivate={props.handleDeactivate}
          // handleDelete={props.handleDelete}
          // handleInventory={props.handleInventory}
          handleView={props.handleView}
          handleViewVariation={props.handleViewVariation}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
          activateTable={props.activateTable}
          productData={props.productData}
          deleteData={props.deleteData}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
