import GridContainer from '@jumbo/components/GridContainer';
import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import { Box, Grid } from '@material-ui/core';
import { select } from '@storybook/addon-knobs';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
// import AlertDelete from '../AlertDelete';
// import AlertDialog from '../AlertDialog';
import TableProductComponent from '../TableProductComponent';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import AlertViewDialog from '../AlertViewDetail';
import AlertProductView from '../AlertProductView';
import AlertProductVariation from '../AlertProductVariation';

const columns = [
  { id: 'productType', label: '' },
  { id: 'productCode', label: 'Product Code' },
  { id: 'productName', label: 'Name' },
  { id: 'productGroupID', label: 'Product Group' },
  { id: 'productPrice', label: 'Price' },
  { id: 'lastModifiedDateTime', label: 'Last Modified' },
  { id: 'productWebActive', label: 'Internet Active' },
  { id: 'actions', label: 'Actions' },
];

const configRowPerPage = JSON.parse(localStorage.getItem('configRowPerPage'));
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const ListProducts = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');

  const [direction, setDirection] = useState(false);
  const [currentColumn, setCurrentColumn] = useState({});
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(
    configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20,
  );
  const [total, setTotal] = useState('');
  const [from, setFrom] = useState(1);
  const [to, setTo] = useState(configRowPerPage && configRowPerPage ? configRowPerPage && configRowPerPage : 20);

  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogVariation, setOpenDialogVariation] = useState(false);

  const [selectID, setSelectID] = useState('');
  // const [activeStatus, setActiveStatus] = useState('');
  // const [activateTable, setActivateTable] = useState('');
  // const [productData, setProductData] = useState([]);
  // const [deleteData, setDeleteData] = useState([]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const [submittedData, setSubmittedData] = useState({
    submit: false,
    productCode: '',
    productGroup: '',
    brand: '',
    category: '',
    status: '',
    name: '',
    webActive: '',
    productType: '',
  });
  const [filterData, setFilterData] = useState({
    productCode: '',
    productGroup: '',
    brand: '',
    category: '',
    status: '',
    name: '',
    webActive: '',
    productType: '',
    removed: '',
  });

  useEffect(() => {
    if (
      filterData.productCode === '' &&
      filterData.productGroup === '' &&
      filterData.brand === '' &&
      filterData.category === '' &&
      filterData.status === '' &&
      filterData.name === '' &&
      filterData.webActive === '' &&
      filterData.productType === ''
    ) {
      setSubmittedData({
        ...submittedData,
        submit: false,
      });
    }
    if (filterData.productCode === ' ') filterData.productCode = '';
    if (filterData.productGroup === ' ') filterData.productGroup = '';
    if (filterData.brand === ' ') filterData.brand = '';
    if (filterData.category === ' ') filterData.category = '';
    if (filterData.status === ' ') filterData.status = '';
    if (filterData.name === ' ') filterData.name = '';
    if (filterData.webActive === ' ') filterData.webActive = '';
    if (filterData.productType === ' ') filterData.productType = '';

    filterData.removed === true && handleSubmitData();
  }, [filterData]);

  // useEffect(() => {
  //   getProducts();
  // }, []);

  useEffect(() => {
    let stateStorage = JSON.parse(localStorage.getItem('products_filter'));
    stateStorage !== null && setFilterData(stateStorage);

    stateStorage == null
      ? getProducts()
      : stateStorage.productCode == '' &&
        stateStorage.productGroup == '' &&
        stateStorage.brand == '' &&
        stateStorage.category == '' &&
        stateStorage.status == '' &&
        stateStorage.name &&
        stateStorage.webActive == '' &&
        stateStorage.productType == '' &&
        stateStorage.removed == false
      ? getProducts()
      : console.log('products');
  }, []);

  const handleSubmitData = () => {
    setSubmittedData({
      productCode: filterData.productCode,
      productGroup: filterData.productGroup,
      brand: filterData.brand,
      category: filterData.category,
      status: filterData.status,
      name: filterData.name,
      webActive: filterData.webActive,
      productType: filterData.productType,
      // search: filterData.search,
      submit: true,
    });
    filterData.removed = true;
    localStorage.setItem('products_filter', JSON.stringify(filterData));
    setLoading(true);
    if (
      filterData.productCode ||
      filterData.productGroup ||
      filterData.brand ||
      filterData.category ||
      filterData.status ||
      filterData.name ||
      filterData.webActive ||
      filterData.productType
    ) {
      httpClient
        .get(
          `frontend/product/filter?productCode=${filterData.productCode}&productGroupID=${filterData.productGroup}&productBrandID=${filterData.brand}&productCategoryID=${filterData.category}&productStatus=${filterData.status}&productName=${filterData.name}&productWebActive=${filterData.webActive}&productType=${filterData.productType}`,
        )
        .then(({ data }) => {
          if (data.success === true) {
            setProducts(data.data);
            setTotal(data.meta.total);
            // setRowsPerPage(data.meta.per_page);
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
    } else {
      getProducts();
    }
  };

  const handleFilter = e => {
    e.preventDefault();
    handleSubmitData();
  };

  const getProducts = () => {
    setLoading(true);
    httpClient.get(`frontend/product?&pagination=${rowsPerPage}`).then(({ data }) => {
      if (data.success === true) {
        setProducts(data.data);
        setTotal(data.meta.total);
        // setRowsPerPage(parseInt(data.meta.per_page));
        setPage(data.meta.current_page);
        setFrom(data.meta.from);
        setTo(data.meta.to);
        setLoading(false);
      }
    });
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFilterData({
      ...filterData,
      [name]: value,
      removed: false,
    });
  };

  const handleRemove = data => {
    setFilterData({
      ...filterData,
      [data]: '',
      removed: true,
    });
    setSubmittedData({
      ...submittedData,
      [data]: '',
    });
  };

  const handleSort = column => {
    setDirection(prevDirection => !prevDirection);
    // console.log('direction', direction);
    setCurrentColumn(column);
    httpClient
      .get(`frontend/product?sort_by=${column.id}&direction=${direction ? 'asc' : 'desc'}&pagination=${rowsPerPage}`)
      .then(({ data }) => {
        if (data.success === true) {
          setProducts(data.data);
        }
      });
  };

  console.log('submitted data', submittedData);

  const handleChangePage = (e, page) => {
    setLoading(true);
    submittedData.submit
      ? httpClient
          .get(
            `frontend/product/filter?productCode=${filterData.productCode}&productGroupID=${filterData.productGroup}&productBrandID=${filterData.brand}&productCategoryID=${filterData.category}&productStatus=${filterData.status}&productName=${filterData.name}&productWebActive=${filterData.webActive}&productType=${filterData.productType}&pagination=${rowsPerPage}&page=${page}`,
          )
          .then(({ data }) => {
            if (data.success === true) {
              setProducts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setPage(data.meta.current_page);
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setLoading(false);
            }
          })
      : httpClient.get(`frontend/product?direction=desc&pagination=${rowsPerPage}&page=${page}`).then(({ data }) => {
          if (data.success === true) {
            setProducts(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setPage(data.meta.current_page);
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setLoading(false);
          }
        });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);

    localStorage.setItem('configRowPerPage', event.target.value);
    submittedData.submit
      ? httpClient
          .get(
            `frontend/product/filter?productCode=${filterData.productCode}&productGroupID=${
              filterData.productGroup
            }&productBrandID=${filterData.brand}&productCategoryID=${filterData.category}&productStatus=${
              filterData.status
            }&productName=${filterData.name}&productWebActive=${filterData.webActive}&productType=${
              filterData.productType
            }&pagination=${+event.target.value}&page=${1}`,
          )
          .then(({ data }) => {
            setLoading(true);
            if (data.success === true) {
              setProducts(data.data);
              setTotal(data.meta.total);
              setRowsPerPage(parseInt(data.meta.per_page));
              setFrom(data.meta.from);
              setTo(data.meta.to);
              setPage(data.meta.current_page);
              setLoading(false);
            }
          })
      : httpClient.get(`frontend/product?direction=desc&pagination=${+event.target.value}&page=${1}`).then(({ data }) => {
          setLoading(true);
          if (data.success === true) {
            setProducts(data.data);
            setTotal(data.meta.total);
            setRowsPerPage(parseInt(data.meta.per_page));
            setFrom(data.meta.from);
            setTo(data.meta.to);
            setPage(data.meta.current_page);
            setLoading(false);
          }
        });
  };

  const handleView = id => {
    setSelectID(id);
    setOpenDialog(true);
  };

  const handleViewVariation = id => {
    setSelectID(id);
    setOpenDialogVariation(true);
  };

  const sendData = child => {
    child.open == false && setOpenDialog(false);
  };

  const sendVariationData = child => {
    child.open == false && setOpenDialogVariation(false);
  };

  //   const handleEdit = val => {
  //     history.push(`/products/addproducts/${val.memID}`);
  //   };

  //   const handleActivate = val => {
  //     setOpenDialog(true);
  //     setSelectID(val.memID);
  //     setActiveStatus('activate');
  //   };

  //   const handleDeactivate = val => {
  //     setOpenDialog(true);
  //     setSelectID(val.memID);
  //     setActiveStatus('Deactivate');
  //   };

  //   const sendDeactivate = child => {
  //     child.open == false && setOpenDialog(false);

  //     if (child.success === true) {
  //       httpClient.post(`/data/source-type/activate-deactivate/${selectID}`).then(({ data }) => {
  //         if (data.success) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           // setTimeout(() => {
  //           //   window.location.reload();
  //           // }, 1500);
  //           // activeStatus === "Activate" ? setActivateTable("handleActivate") : setActivateTable("handleDeactivate");
  //           // getProducts();
  //           setProductData(data.data);
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed to Update the Data Source');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //   };

  //   const handleDelete = val => {
  //     setSelectID(val.memID);
  //     setOpenDeleteDialog(true);
  //   };

  //   const sendDelete = child => {
  //     child.open === false && setOpenDeleteDialog(false);
  //     if (child.success === true) {
  //       httpClient.delete(`/data/source-type/${selectID}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setOpen(true);
  //           setMessage(data.message);
  //           setMessageState('success');
  //           setDeleteData(data.data);
  //           getProducts();
  //         } else {
  //           setOpen(true);
  //           setMessage('Failed to Delete the Data Source');
  //           setMessageState('error');
  //         }
  //       });
  //     }
  //   };

  //   const onChangeSearch = e => {
  //     setSearchText(e.target.value);
  //     e.target.value === '' && getstates();
  //   };
  //   const handleSearch = e => {
  //     if (e.key === 'Enter') {
  //       httpClient.get(`state/search?q=${searchText}`).then(({ data }) => {
  //         if (data.success === true) {
  //           setProducts(data.data);
  //         } else {
  //           console.log('search failed');
  //         }
  //       });
  //     }
  //   };

  //   const handleDefault = e => {
  //     setSearchText(e.target.value);
  //   };

  return (
    <PageContainer heading="Products">
      <GridContainer>
        <Grid item xs={12} md={12}>
          <Box pt={2} pb={2}>
            <TableProductComponent
              columns={columns}
              selectedProducts={products}
              title="List Products"
              handleView={handleView}
              handleViewVariation={handleViewVariation}
              //   handleEdit={handleEdit}
              //   handleActivate={handleActivate}
              //   handleDeactivate={handleDeactivate}
              //   handleDelete={handleDelete}
              // onChangeSearch={e => onChangeSearch(e)}
              // searchValue={searchText}
              // onKeyPress={e => handleSearch(e)}
              // onKeyDown={e => handleDefault(e)}
              loading={loading}
              handleSort={handleSort}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
              page={page}
              total={total}
              fromTable={from}
              toTable={to}
              rowsPerPage={rowsPerPage}
              handleFilter={handleFilter}
              filterData={filterData}
              submittedData={submittedData}
              handleChange={handleChange}
              handleRemove={handleRemove}
              direction={direction}
              currentColumn={currentColumn}
              //   activateTable={activateTable}
              //   productData={productData}
              //   deleteData={deleteData}
            />
          </Box>
        </Grid>
      </GridContainer>
      {openDialog && <AlertProductView sendData={sendData} selectID={selectID} />}

      {openDialogVariation && <AlertProductVariation sendVariationData={sendVariationData} selectID={selectID} />}

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {message}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
};

export default ListProducts;
