import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';
import AddContact from './AddContact';
import ListBranches from './ListBranches';
import ListContacts from './ListContacts';
import ListCustomerGroup from './ListCustomerGroup';
import ListCustomers from './ListCustomers';
import ListProductGroup from './ListProductGroup';
import ListProducts from './ListProducts';
import StockOnHand from './StockOnHand';

const DataSource = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/branches`} />
        <Route path={`${requestedUrl}/branches`} component={ListBranches} />
        <Route path={`${requestedUrl}/customers`} component={ListCustomers} />
        <Route path={`${requestedUrl}/customerGroup`} component={ListCustomerGroup} />

        <Route path={`${requestedUrl}/products`} component={ListProducts} />
        <Route path={`${requestedUrl}/productGroup`} component={ListProductGroup} />

        <Route path={`${requestedUrl}/contacts`} component={ListContacts} />
        <Route path={`${requestedUrl}/addContact`} component={AddContact} />
        <Route path={`${requestedUrl}/stockOnHand`} component={StockOnHand} />

        <Route component={lazy(() => import('../ExtraPages/404'))} />
      </Switch>
    </Suspense>
  );
};

export default DataSource;
