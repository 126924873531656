import PageContainer from '@jumbo/components/PageComponents/layouts/PageContainer';
import {
  Button,
  Card,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Snackbar,
  TextField,
} from '@material-ui/core';
import { httpClient } from 'appUtility/Api';
import React, { useEffect, useState } from 'react';
import { history } from 'redux/store';
import MuiAlert from '@material-ui/lab/Alert';

const qs = require('qs');

const useStyles = makeStyles(theme => ({
  root: {
    padding: '40px',
  },
  form: {
    width: '100%',
  },
  text: {
    width: '100%',
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function ChangeProfile() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState([]);
  const [messageState, setMessageState] = useState('');

  const [loading, setLoading] = useState(false);

  const loginData = JSON.parse(sessionStorage.getItem('login'));
  const [profileDetails, setProfileDetails] = useState({
    firstName: '',
    lastName: '',
    username: '',
    position: '',
    mobile: '',
    phone: '',
    contactActive: '',
    changePassword: false,
    password: '',
    confirmPassword: '',
  });

  useEffect(() => {
    setProfileDetails({
      ...profileDetails,
      firstName: loginData && loginData.company.firstName,
      lastName: loginData && loginData.company.lastName,
      username: loginData && loginData.company.email_username,
      position: loginData && loginData.company.position,
      phone: loginData && loginData.company.phone,
      mobile: loginData && loginData.company.mobile,
      contactActive: loginData && loginData.company.contactActive == 1 ? true : false,
    });
  }, []);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setProfileDetails({
      ...profileDetails,
      [name]: value,
    });
  };

  const handleCheck = e => {
    const { name, checked } = e.target;
    setProfileDetails({
      ...profileDetails,
      [name]: checked,
    });
  };

  const handleBack = () => {
    history.push('/dashboard');
  };

  const handleSubmit = e => {
    e.preventDefault();
    setLoading(true);

    httpClient
      .post(
        '/change-profile',
        qs.stringify({
          firstName: profileDetails.firstName,
          lastName: profileDetails.lastName,
          email_username: profileDetails.username,
          position: profileDetails.position,
          mobile: profileDetails.mobile,
          phone: profileDetails.phone,
          contactActive: profileDetails.contactActive === true ? 1 : 0,
          changePassword: profileDetails.changePassword === true ? 1 : 0,
          password: profileDetails.password,
          confirmPassword: profileDetails.confirmPassword,
        }),
      )
      .then(({ data }) => {
        if (data.success === true) {
          setOpen(true);
          setMessageState('success');
          setMessage(data.message);
          setLoading(false);
          setProfileDetails({
            firstName: loginData !== null ? loginData.company.firstName : '',
            lastName: loginData !== null ? loginData.company.lastName : '',
            username: loginData !== null ? loginData.company.email_username : '',
            position: loginData !== null ? loginData.company.position : '',
            phone: loginData !== null ? loginData.company.phone : '',
            mobile: loginData !== null ? loginData.company.mobile : '',
            contactActive: loginData !== null ? (loginData.company.contactActive == 1 ? true : false) : '',
            changePassword: false,
            password: '',
            confirmPassword: '',
          });
          var login = JSON.parse(sessionStorage.getItem('login'));
          login.company.firstName = data.data.firstName;
          login.company.lastName = data.data.lastName;
          login.company.email_username = data.data.email_username;
          login.company.mobile = data.data.mobile;
          login.company.phone = data.data.phone;
          login.company.position = data.data.position;
          login.company.contactActive = data.data.contactActive;

          sessionStorage.setItem('login', JSON.stringify(login));

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          setOpen(true);
          setMessageState('error');
          setMessage(data.message);
          setLoading(false);
        }
      });
  };
  return (
    <PageContainer heading={'Change Profile'}>
      <Card className={classes.root}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={4}>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="First Name"
                variant="outlined"
                name="firstName"
                type="text"
                value={profileDetails.firstName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Last Name"
                variant="outlined"
                name="lastName"
                type="text"
                value={profileDetails.lastName}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Username"
                variant="outlined"
                name="username"
                type="text"
                value={profileDetails.username}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                required
                label="Position"
                variant="outlined"
                name="position"
                type="text"
                value={profileDetails.position}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                // required
                label="Phone"
                variant="outlined"
                name="phone"
                type="text"
                value={profileDetails.phone}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={6}>
              <TextField
                // required
                label="Mobile"
                variant="outlined"
                name="mobile"
                type="text"
                value={profileDetails.mobile}
                className={classes.text}
                onChange={handleChange}
              />
            </Grid>

            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="contactActive"
                    checked={profileDetails.contactActive}
                    onChange={handleCheck}
                    value={profileDetails.contactActive}
                    color="primary"
                  />
                }
                label="Profile Active?"
              />
            </Grid>

            <Grid item sm={12} md={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    name="changePassword"
                    checked={profileDetails.changePassword}
                    onChange={handleCheck}
                    value={profileDetails.changePassword}
                    color="primary"
                  />
                }
                label="Change Password ?"
              />
            </Grid>

            {profileDetails.changePassword == true && (
              <Grid item sm={12} md={12}>
                <TextField
                  required
                  label="password"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={profileDetails.password}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}

            {profileDetails.changePassword == true && (
              <Grid item sm={12} md={12}>
                <TextField
                  label="Confirm Password"
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  helperText={profileDetails.password !== profileDetails.confirmPassword && 'Password does not match!'}
                  value={profileDetails.confirmPassword}
                  className={classes.text}
                  onChange={handleChange}
                />
              </Grid>
            )}
            <Grid item sm={12} md={12} style={{ textAlign: 'right' }}>
              <Button color="secondary" variant="contained" style={{ marginRight: '10px' }} onClick={handleBack}>
                Back
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={profileDetails.password !== profileDetails.confirmPassword ? true : false}>
                {'Submit'}
              </Button>
              {loading && <CircularProgress size={20} style={{ marginLeft: '10px' }} />}
            </Grid>
          </Grid>
        </form>
      </Card>

      <Snackbar
        autoHideDuration={3000}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}>
        <Alert onClose={handleClose} severity={messageState}>
          {messageState === 'success' ? message : message.length > 0 && message.map(msg => <p>{msg}</p>)}
        </Alert>
      </Snackbar>
    </PageContainer>
  );
}

export default ChangeProfile;
