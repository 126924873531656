import React, { useState, useEffect } from 'react';
// import CmtCard from '../../../../@coremat/CmtCard';
// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';
// import { intranet } from '../../../../@fake-db';
// import CmtCardContent from '../../../../@coremat/CmtCard/CmtCardContent';
// import ProductsList from './ProductsList';
import ProductsTable from './ProductsTable';
import Box from '@material-ui/core/Box';
import useStyles from './index.style';
import CmtCardHeader from '@coremat/CmtCard/CmtCardHeader';
import CmtCardContent from '@coremat/CmtCard/CmtCardContent';
import CmtCard from '@coremat/CmtCard';
import { Button, Collapse, Grid, MenuItem, FormControl, InputLabel, Select, TextField } from '@material-ui/core';
import { history } from 'redux/store';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import FilterList from '@material-ui/icons/FilterList';
import { httpClient } from 'appUtility/Api';

const WeeklySales = props => {
  // const { productsList } = intranet;
  const classes = useStyles();
  // const [selectedProducts, setSelectedProducts] = useState(productsList);
  // const [showChart, setShowChart] = useState(false);
  const [filter, setFilter] = useState(false);
  // const [group, setGroup] = useState([]);
  // const [categories, setCategories] = useState([]);
  // const [brands, setBrands] = useState([]);
  // useEffect(() => {
  //   getGroups();
  //   getCategories();
  //   getBrands();
  // }, []);

  useEffect(() => {
    props.submittedData.submit === true && setFilter(false);
  }, [props.submittedData]);

  // const getGroups = () => {
  //   httpClient.get('/group/all').then(({ data }) => {
  //     if (data) {
  //       setGroup(data.data);
  //     } else {
  //     }
  //   });
  // };

  // const getCategories = () => {
  //   httpClient.get('/product-category/all').then(({ data }) => {
  //     if (data) {
  //       setCategories(data.data);
  //     } else {
  //     }
  //   });
  // };

  // const getBrands = () => {
  //   httpClient.get('/brand/all').then(({ data }) => {
  //     if (data) {
  //       setBrands(data.data);
  //     } else {
  //     }
  //   });
  // };

  const handleFilterButton = () => {
    setFilter(prev => !prev);
  };

  // var groupName, categoryName, brandName;
  // group &&
  //   group.map(grp => {
  //     if (grp.groupID == props.submittedData.productGroup) {
  //       groupName = grp.erplyGroupName;
  //     }
  //   });

  // categories &&
  //   categories.map(grp => {
  //     if (grp.categoryID == props.submittedData.category) {
  //       categoryName = grp.erplyCategoryName;
  //     }
  //   });

  // brands &&
  //   brands.map(brand => {
  //     if (brand.brandID == props.submittedData.brand) {
  //       brandName = brand.erplyBrandName;
  //     }
  //   });

  return (
    <CmtCard className={classes.cardRoot}>
      <div className={classes.tableHead}>
        <CmtCardHeader title={props.title} />
        <div className={classes.flexSearch}>
          {/* <div className={classes.formGroup}>
            <input
              placeholder="Search..."
              type="search"
              onChange={props.onChangeSearch}
              value={props.searchValue}
              onKeyPress={props.onKeyPress}
              onKeyDown={props.onKeyDown}
              className={classes.searchForm}
            />
            {!props.searchValue && <SearchIcon fontSize="small" className={classes.searchIcon} />}
          </div> */}
          <Button color="secondary" variant="contained" onClick={handleFilterButton} style={{ marginRight: '10px' }}>
            <FilterList fontSize="small" /> Filter
          </Button>
          {/* <Button color="primary" variant="contained" onClick={sendTo}  style={{ marginRight: '10px' }}>
            +Add
          </Button> */}
        </div>
      </div>
      <CmtCardContent>
        {filter && (
          <Collapse in={filter} className={classes.filterBox}>
            <form onSubmit={props.handleFilter}>
              <Grid container spacing={4}>
                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Group Name </InputLabel>
                  <TextField
                    variant="outlined"
                    name="groupName"
                    value={props.filterData.groupName}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>
                {/* <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Group ID </InputLabel>
                  <TextField
                    variant="outlined"
                    name="groupID"
                    value={props.filterData.groupID}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid> */}
                {/* <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Web Active</InputLabel>
                  <FormControl variant="outlined" className={classes.text}>
                    <Select
                      name="webActive"
                      value={props.filterData.webActive}
                      onChange={props.handleChange}
                      className={classes.text}>
                      <MenuItem value=" ">Select Status</MenuItem>
                      <MenuItem value="1">Active</MenuItem>
                      <MenuItem value="0">Inactive</MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item sm={12} md={6}>
                  <InputLabel className={classes.inputLabel}>Search</InputLabel>
                  <TextField
                    variant="outlined"
                    name="search"
                    value={props.filterData.search}
                    className={classes.text}
                    onChange={props.handleChange}
                  />
                </Grid>

                <Grid item sm={12}>
                  <Box textAlign="right">
                    <Button color="secondary" size="small" onClick={() => setFilter(false)} style={{ marginRight: '10px' }}>
                      Cancel
                    </Button>
                    <Button color="primary" size="small" variant="contained" type="submit" style={{ marginRight: '10px' }}>
                      Filter Data
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Collapse>
        )}

        {props.submittedData.groupName ||
        // props.submittedData.webActive ||
        // props.submittedData.brand ||
        // props.submittedData.category ||
        // props.submittedData.status ||
        // props.submittedData.name ||
        props.submittedData.search ? (
          <Box className={classes.productView}>
            <p>Filtered: </p>
            {props.submittedData.groupName && (
              <p>
                Group Name : <span>{props.submittedData.groupName} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('groupName')} />
              </p>
            )}
            {/* {props.submittedData.webActive && (
              <p>
                Web Active: <span> {props.submittedData.webActive == "1" ? "Active" : "Inactive"} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('webActive')} />
              </p>
            )} */}
            {props.submittedData.search && (
              <p>
                Search: <span> {props.submittedData.search} </span>
                <Close fontSize="small" onClick={() => props.handleRemove('search')} />
              </p>
            )}
          </Box>
        ) : (
          <Box className={classes.productView} />
        )}
        <ProductsTable
          columns={props.columns}
          selectedProducts={props.selectedProducts}
          // handleEdit={props.handleEdit}
          // handleActivate={props.handleActivate}
          // handleDeactivate={props.handleDeactivate}
          // handleDelete={props.handleDelete}
          // handleInventory={props.handleInventory}
          handleView={props.handleView}
          handleViewVariation={props.handleViewVariation}
          loading={props.loading}
          handleSort={props.handleSort}
          handleChangePage={props.handleChangePage}
          handleChangeRowsPerPage={props.handleChangeRowsPerPage}
          page={props.page}
          total={props.total}
          fromTable={props.fromTable}
          toTable={props.toTable}
          rowsPerPage={props.rowsPerPage}
          currentColumn={props.currentColumn}
          direction={props.direction}
          activateTable={props.activateTable}
          productData={props.productData}
          deleteData={props.deleteData}
        />
      </CmtCardContent>
    </CmtCard>
  );
};

export default WeeklySales;
