import React, { useEffect, useState } from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade, IconButton, TableFooter, TablePagination, Tooltip, CircularProgress, Button } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import { Edit, ExpandLess, ExpandMore, LockOpen, UnfoldMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { useTheme } from '@material-ui/core/styles';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import MoreVert from '@material-ui/icons/MoreVert';
import Delete from '@material-ui/icons/Delete';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  scrollbarRoot: {
    // height: 251,
    marginLeft: -24,
    marginRight: -24,
    [theme.breakpoints.up('xl')]: {
      // height: 269,
    },
  },
  tableRowRoot: {
    position: 'relative',
    transition: 'all .2s',
    borderTop: `solid 1px ${theme.palette.borderColor.main}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.primary.main, 0.08),
      transform: 'translateY(-4px)',
      boxShadow: `0 3px 10px 0 ${fade(theme.palette.common.dark, 0.2)}`,
      borderTopColor: 'transparent',
      '& $tableCellRoot': {
        color: theme.palette.text.primary,
        '&:last-child': {
          color: theme.palette.error.main,
        },
        '&.success': {
          color: theme.palette.success.main,
        },
      },
    },
    '&:last-child': {
      borderBottom: `solid 1px ${theme.palette.borderColor.main}`,
    },
  },
  tableCellRoot: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: 14,
    letterSpacing: 0.4,
    color: theme.palette.common.dark,
    borderBottom: '0 none',
    '&:first-child': {
      paddingLeft: 24,
    },
    '&:last-child': {
      textAlign: 'right',
      paddingRight: 24,
    },
  },
  tableCellWidth: {
    minWidth: '200px',
  },
  tableRowCellRoot: {
    fontSize: 12,
    '&:last-child': {
      paddingRight: 64,
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
    },
    '& .MuiSvgIcon-root': {
      fontSize: '13px',
      position: 'relative',
      top: '2px',
      cursor: 'pointer',
    },
  },
  blockRoot: {
    display: 'block',
    fontSize: 14,
  },
  loader: {
    position: 'relative',
    height: '150px',
  },
  loadingSection: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  flexBtn: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

const actions = [
  {
    label: 'View Profile',
  },
  {
    label: 'More',
  },
];

var loginData = JSON.parse(sessionStorage.getItem('login'));

function TablePaginationActions(props) {
  const classes = useStyles();
  const { count, page, rowsPerPage, onPageChange } = props;
  const theme = useTheme();

  const handleBackButtonClick = event => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onPageChange(event, page + 1);
  };

  return (
    <div className={classes.flexBtn}>
      <IconButton onClick={handleBackButtonClick} disabled={page === 1} aria-label="previous page">
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage)} aria-label="next page">
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const ProductsTable = ({
  columns,
  selectedProducts,
  handleView,
  handleEdit,
  handleActivate,
  handleDeactivate,
  activateTable,
  productData,
  deleteData,
  loading,
  handleSort,
  rowsPerPage,
  page,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  toTable,
  fromTable,
  currentColumn,
  direction,
  handleDelete,
}) => {
  const classes = useStyles();
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(10);
  const [active, setActive] = useState(false);
  const [rowID, setRowID] = useState('');
  var [branches, setBranches] = useState([]);

  branches = selectedProducts && selectedProducts;

  const actions = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: handleEdit,
    },
    {
      icon: <LockOpen color="secondary" fontSize="small" />,
      label: active ? 'Deactivate' : 'Activate',
      onClick: active ? handleDeactivate : handleActivate,
    },
  ];

  const actions2 = [
    {
      icon: <Edit color="secondary" fontSize="small" />,
      label: 'Edit',
      onClick: handleEdit,
    },
    {
      icon: <LockOpen color="secondary" fontSize="small" />,
      label: active ? 'Deactivate' : 'Activate',
      onClick: active ? handleDeactivate : handleActivate,
    },
    {
      icon: <Delete color="secondary" fontSize="small" />,
      label: 'Delete',
      onClick: handleDelete,
    },
  ];

  // useEffect(() => {
  //   branches &&
  //     branches.map(data => {
  //       if (data.dataSourceTypeID === rowID) {
  //         data.dataSourceTypeActive === 'Active' ? setActive(true) : setActive(false);
  //       }
  //     });
  // }, [rowID]);

  // useEffect(() =>{
  //   if(activateTable === "handleActivate"){
  //     setActive(true);
  //   }else if(activateTable === "handleDeactivate"){
  //     setActive(false);
  //   }else {}
  // },[selectedProducts])

  //   useEffect(() => {
  //     if (productData.dataSourceTypeActive == 'Active') {
  //       setActive(true);
  //     } else if (productData.dataSourceTypeActive == 'Inactive') {
  //       setActive(false);
  //     } else {
  //     }

  //     branches &&
  //       branches.map(source => {
  //         if (source.dataSourceTypeID == productData.dataSourceTypeID) {
  //           return (source.dataSourceTypeActive = productData.dataSourceTypeActive);
  //         }
  //       });

  //     setBranches(branches);
  //   }, [productData]);

  //   if(deleteData){
  //   var delArr = branches.filter(source => source.dataSourceTypeID != deleteData.dataSourceTypeID);
  //   branches = delArr;
  // }

  // console.log('deleted data', deleteData);
  // console.log('source', branches);

  const handleRowID = (e, id) => {
    setRowID(id);
  };

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };
  // const handleChangeRowsPerPage = event => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };
  return (
    <PerfectScrollbar className={classes.scrollbarRoot}>
      <Box className="Cmt-table-responsive">
        <Table>
          <TableHead>
            <TableRow>
              {columns.map(column => (
                <TableCell
                  key={column.id}
                  onClick={() => handleSort(column)}
                  className={clsx(classes.tableCellRoot, classes.tableRowCellRoot)}>
                  {' '}
                  {currentColumn === column ? <span style={{ fontWeight: '900' }}>{column.label}</span> : column.label}
                  {currentColumn === column ? direction ? <ExpandLess /> : <ExpandMore /> : <UnfoldMore />}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {loading ? (
            <TableBody className={classes.loader}>
              <Box textAlign="center" className={classes.loadingSection}>
                <CircularProgress />
              </Box>
            </TableBody>
          ) : (
            <TableBody>
              {branches &&
                // selectedProducts.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                branches.map((row, index) => {
                  // const isInflationPositive = row.sales_data.sales_inflation > 0;
                  return (
                    <TableRow className={classes.tableRowRoot} key={Math.random()}>
                      <TableCell className={classes.tableCellRoot}>{row.firstName ? row.firstName : '-'}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.lastName ? row.lastName : '-'}</TableCell>
                      <TableCell className={classes.tableCellRoot}>{row.phone ? row.phone : '-'}</TableCell>
                      <TableCell className={classes.tableCellRoot}>
                        {row.email_username ? row.email_username : '-'}
                      </TableCell>
                      {/* <TableCell className={classes.tableCellRoot}>
                        {moment(row.lastModifiedDateTime).format('dddd, Do MMMM YYYY')}
                      </TableCell> */}

                      <TableCell className={classes.tableCellRoot}>
                        {row.primaryContact == '1' ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCellRoot}>
                        {row.hasAccessToAdminPanel == '1' ? (
                          <CheckIcon fontSize="small" color="primary" />
                        ) : (
                          <ClearIcon fontSize="small" color="secondary" />
                        )}
                      </TableCell>

                      <TableCell className={classes.tableCellRoot}>
                        {/* <Box display="flex" alignItems="center" justifyContent="flex-end"> */}
                        {/* {row.sales_data.income} */}
                        {/* <CmtDropdownMenu
                            TriggerComponent={
                              <Tooltip title="More">
                                <IconButton size="small" style={{ marginLeft: 10 }}>
                                  <MoreVert />
                                </IconButton>
                              </Tooltip>
                            }
                            items={row.canDelete === 'Yes' ? actions2 : actions}
                            memID={row.dataSourceTypeID}
                            // onItemClick={(e) => handleRowID(e,row.inventoryOrderID)}
                          />
                        </Box> */}

                        {loginData.company.primaryContact == '1' && loginData.company.hasAccessToAdminPanel == '1' && (
                          <Button
                            onClick={() => handleEdit(row.contactID)}
                            color="secondary"
                            variant="contained"
                            size="small"
                            style={{ marginRight: '5px' }}>
                            Edit
                          </Button>
                        )}
                        <Button onClick={() => handleView(row.contactID)} color="primary" variant="contained" size="small">
                          View
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {selectedProducts.length === 0 && (
                <TableRow
                  style={{
                    textAlign: 'center',
                    width: '100%',
                    position: 'absolute',
                  }}>
                  No Contacts
                </TableRow>
              )}
            </TableBody>
          )}
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[20, 50, 70, 100]}
                rowsPerPage={rowsPerPage}
                page={page}
                count={total && total}
                SelectProps={{
                  native: true,
                }}
                labelDisplayedRows={() =>
                  `${fromTable !== null ? fromTable : 0} - ${toTable !== null ? toTable : 0} to ${total}`
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </PerfectScrollbar>
  );
};

export default ProductsTable;
