import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { httpClient } from 'appUtility/Api';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  headerInside: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
  },
  close: {
    color: '#fff',
  },
  content: {
    padding: '40px',
  },
  gridColumn: {
    padding: '20px',
  },
  mb: {
    marginBottom: '20px',
  },
  date: {
    marginTop: '20px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontStyle: 'italic',
    fontSize: '12px',
  },
  sub: {
    color: '#666',
  },
  loader: {
    height: '150px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  card: {
    fontSize: '15px',
  },
  cardHeading: {
    padding: '20px',
    borderBottom: `20px solid ${theme.palette.secondary.main}`,
  },
  productHead: {
    marginBottom: '10px',
    fontWeight: '900',
  },
  true: {
    padding: '5px 10px',
    borderRadius: '10px',
    backgroundColor: theme.palette.success.main,
    color: '#fff',
    fontSize: '10px',
    width: 'fit-content',
  },
  false: {
    padding: '5px',
    borderRadius: '10px',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    fontSize: '10px',
    width: 'fit-content',
  },
  gridRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
  },
}));

const AlertViewDialog = props => {
  const classes = useStyles();

  const [alertData, setAlertData] = useState({
    open: true,
  });

  const [warehouseData, setWarehouseData] = useState({});
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    props.selectID &&
      httpClient.get(`frontend/warehouse/${props.selectID}`).then(({ data }) => {
        if (data.success == true) {
          setWarehouseData(data.data);
          setLoading(false);
        } else {
          setLoading(false);
        }
      });
  }, []);

  const handleClose = () => {
    setAlertData({
      ...alertData,
      open: false,
    });
  };
  //   console.log('props', props);

  props.sendData(alertData);

  return (
    <Dialog open={alertData.open} onClose={handleClose} maxWidth={'lg'} fullWidth={true}>
      {loading ? (
        <Box className={classes.loader}>
          <CircularProgress />
        </Box>
      ) : (
        <div>
          <DialogTitle className={classes.header}>
            <div className={classes.headerInside}>
              <div>
                <h3>{warehouseData.erplyWarehouseCompanyName ? warehouseData.erplyWarehouseCompanyName : '-'}</h3>
              </div>
              <IconButton onClick={handleClose} className={classes.close}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent className={classes.content}>
            <Card className={classes.card}>
              <Grid container className={classes.cardHeading}>
                Code : {warehouseData.erplyClientCode ? warehouseData.erplyClientCode : '-'}
              </Grid>

              <Grid container spacing={4} className={classes.gridColumn}>
                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Company </div>
                  <div className={classes.sub}>
                    {' '}
                    {warehouseData.erplyWarehouseCompanyName}
                    {warehouseData.erplyWarehouseCompanyCode &&
                      ' ' + '(' + warehouseData.erplyWarehouseCompanyName2 + ')'}{' '}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Email </div>{' '}
                  <div className={classes.sub}>
                    {' '}
                    {warehouseData.erplyWarehouseEmail ? warehouseData.erplyWarehouseEmail : '-'}{' '}
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Address</div>{' '}
                  <div className={classes.sub}>
                    {' '}
                    {warehouseData.erplyWarehouseStreet}{' '}
                    {warehouseData.erplyWarehouseAddress2 && ' ' + ',' + ' ' + warehouseData.erplyWarehouseAddress2}
                    {warehouseData.erplyWarehouseCity && ' ' + ',' + ' ' + warehouseData.erplyWarehouseCity}
                    {warehouseData.erplyWarehouseZipCode && ' ' + ',' + ' ' + warehouseData.erplyWarehouseZipCode}
                    {warehouseData.erplyWarehouseState && ' ' + ',' + ' ' + warehouseData.erplyWarehouseState}
                    {warehouseData.erplyWarehouseCountry && ' ' + ',' + ' ' + warehouseData.erplyWarehouseCountry}{' '}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Phone</div>
                  <div className={classes.sub}>
                    {warehouseData.erplyWarehousePhone ? warehouseData.erplyWarehousePhone : '-'}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Fax </div>
                  <div className={classes.sub}>
                    {warehouseData.erplyWarehouseFax ? warehouseData.erplyWarehouseFax : '-'}
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} className={classes.gridRow}>
                  <div>Region</div>{' '}
                  <div className={classes.sub}>
                    {' '}
                    {warehouseData.erplyWarehouseRegionID ? warehouseData.erplyWarehouseRegionID : '-'}{' '}
                  </div>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <div className={classes.date}>
                    <div>ID: {warehouseData.warehouseID}</div>
                    <div>
                      Last Updated:{' '}
                      {warehouseData.lastModifiedDateTime == '0000-00-00 00:00:00'
                        ? '-'
                        : moment(warehouseData.lastModifiedDateTime).format('dddd, Do MMMM YYYY HH:mm A')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </DialogContent>
        </div>
      )}
    </Dialog>
  );
};

export default AlertViewDialog;
